import React from 'react'
import PropTypes from 'prop-types'
import 'reset-css'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/core'
import { styles } from '../../theme/globalStyles'
import * as S from './styles'
import Sidebar from '../Sidebar'
import Page from '../Page'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,400;0,600;1,400;1,600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Global styles={styles} />
    <S.Layout>
      <Sidebar />
      <Page>{children}</Page>
    </S.Layout>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
