import React from 'react'
import styled from '@emotion/styled'

const Footer = () => (
  <FooterWrapper>
    <small>All Rights Reserved &copy; {new Date().getFullYear()}</small>
  </FooterWrapper>
)

export default Footer

const FooterWrapper = styled.footer`
  border-top: 3px solid var(--beau);
  margin-top: var(--xl);
  padding-top: var(--md);
  color: var(--cream);
`
