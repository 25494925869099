import styled from '@emotion/styled'

export const NavWrapper = styled.nav`
  text-align: center;
`

export const NavMenu = styled.ul`
  margin: 0 0 var(--xl);
  list-style: none;
  font-family: var(--bebas);
  font-weight: 400;
  font-size: var(--md);

  li:not(:first-of-type) {
    margin-top: var(--xxs);
  }

  a {
    text-decoration: none;
    color: var(--bistre);
    transition: color 0.15s ease-in-out;
    :hover {
      color: var(--cream);
    }
  }
`
