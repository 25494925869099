import styled from '@emotion/styled'
import { queries } from '../../theme/globalStyles'

export const Layout = styled.div`
  display: block;
  grid-template-columns: 1fr;
  align-items: start;
  width: calc(100% - var(--xl) * 2);
  max-width: 1280px;
  margin: var(--xxl) auto;

  ${queries[1]} {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: var(--xl);
  }

  ${queries[2]} {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: var(--xxl);
    width: calc(100% - var(--xxl) * 2);
  }
`
