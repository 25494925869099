import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Footer from '../Footer'

const Page = ({ children }) => (
  <div>
    <Fade delay="750" duration="1000">
      <main>{children}</main>
      <Footer />
    </Fade>
  </div>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
