import React from 'react'
import { Link } from 'gatsby'
import Logo from '../Logo'
import * as S from './styles'

const Nav = () => (
  <S.NavWrapper>
    <Link to="/">
      <Logo />
    </Link>
    <S.NavMenu>
      <li>
        <Link to="/menu">Our Menu</Link>
      </li>
      <li>
        <Link to="/find-us">Find Us</Link>
      </li>
    </S.NavMenu>
  </S.NavWrapper>
)

export default Nav
