import styled from '@emotion/styled'
import { queries } from '../../theme/globalStyles'

export const SidebarWrapper = styled.div`
  position: relative;
  margin-bottom: var(--xxl);

  ${queries[1]} {
    position: sticky;
    top: var(--xxl);
  }

  img {
    margin: 0 auto var(--xxl);
  }
`
