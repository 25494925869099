import styled from '@emotion/styled'
import Img from 'gatsby-image'

export const LogoImage = styled(Img)`
  margin: 0 auto var(--lg);
  display: block;
  max-width: 20rem;
  width: 100%;
  height: auto;
  mix-blend-mode: multiply;
  transition: transform 0.15s ease-in-out;
  :hover {
    transform: scale(1.025);
  }
`
