import { css } from '@emotion/core'

// Breakpoints and Queries
const breakpoints = [480, 768, 1024]
export const queries = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

// Global Styles
export const styles = css`
  :root {
    --white: #fcfeff;
    --beau: #e0e0e0;
    --cream: #665252;
    --bistre: #4b2827;
    --bebas: 'Bebas Neue', cursive;
    --montserrat: 'Montserrat', sans-serif;
    --xxl: 3.998rem;
    --xl: 2.827rem;
    --lg: 1.999rem;
    --md: 1.414rem;
    --sm: 1rem;
    --xs: 0.707rem;
    --xxs: 0.5rem;
  }
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 100%;
    text-size-adjust: 100%;
  }
  body {
    font-family: var(--montserrat);
    font-weight: 400;
    line-height: var(--lg);
    color: var(--cream);
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: var(--xl) 0;
    font-family: var(--bebas);
    font-weight: 400;
    line-height: var(--lg);
    color: var(--bistre);
  }
  h1 {
    margin: var(--xs) 0 var(--xl);
    font-size: var(--xxl);
    line-height: var(--xxl);
  }
  h2 {
    font-size: var(--xl);
    line-height: var(--xl);
  }
  h3 {
    font-size: var(--lg);
    margin-bottom: var(--md);
  }
  h4 {
    font-size: var(--md);
  }
  h5 {
    font-size: var(--sm);
    margin: var(--lg) 0 0;
  }
  b,
  strong {
    font-weight: 600;
  }
  i,
  em {
    font-weight: inherit;
    font-style: italic;
  }
  small {
    font-size: var(--xs);
  }
  p,
  pre,
  table,
  blockquote {
    margin: 0 0 var(--md);
  }
  ul,
  ol {
    list-style: disc;
    margin: 0 0 var(--sm) var(--xl);
  }
  hr {
    border: 1px solid;
    margin: -1px 0;
  }
  a,
  b,
  i,
  strong,
  em,
  small,
  code {
    line-height: 0;
  }
  img {
    display: block;
    margin-bottom: var(--xxl);
  }
  a {
    color: var(--bistre);
    transition: color 0.25s ease-in-out;
    :hover {
      color: var(--cream);
    }
  }
`
