import React from 'react'
import {
  FaEnvelope,
  FaYelp,
  FaPhone,
  FaInstagram,
  FaFacebook,
} from 'react-icons/fa'
import * as S from './styles'

const Social = () => (
  <S.SocialMenu>
    <li>
      <a
        href="https://www.facebook.com/bigshotcoffeehouse"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebook />
      </a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/bigshotcoffeehouse/?hl=en"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram />
      </a>
    </li>
    <li>
      <a
        href="https://www.yelp.com/biz/big-shot-coffee-house-tyler"
        target="_blank"
        rel="noreferrer"
      >
        <FaYelp />
      </a>
    </li>
    <li>
      <a href="tel:903-630-2006" target="_blank" rel="noreferrer">
        <FaPhone />
      </a>
    </li>
    <li>
      <a
        href="mailto:info@bigshotcoffeehouse.com"
        target="_blank"
        rel="noreferrer"
      >
        <FaEnvelope />
      </a>
    </li>
  </S.SocialMenu>
)

export default Social
