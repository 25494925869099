import styled from '@emotion/styled'
import { queries } from '../../theme/globalStyles'

export const SocialMenu = styled.ul`
  list-style: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: var(--md);
  max-width: 300px;

  li > a {
      color: var(--cream);
      transition: color 0.25s ease-in-out;
      :hover {
        color: var(--bistre);
      }
      svg {
        transition: transform 0.15s ease-in-out;
        :hover {
          transform: scale(1.2);
        }
      }
    }
  }

  ${queries[1]} {
    max-width: 100%;
  }
`
