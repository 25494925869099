import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styles'

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "bsch-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <S.LogoImage
      fluid={data.logoImage.childImageSharp.fluid}
      alt="Big Shot Coffee House"
    />
  )
}

export default Logo
