import React from "react"
import Fade from "react-reveal/Fade"
import Nav from "../Nav"
import Social from "../Social"
import Alert from "../Alert"
import * as S from "./styles"

const Sidebar = () => (
  <S.SidebarWrapper>
    <Fade delay="500" duration="500">
      <Nav />
      <Social />
      {/* <Alert /> */}
    </Fade>
  </S.SidebarWrapper>
)

export default Sidebar
